import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout style={{ height: "70vh" }}>
    <SEO title="404: Not found" />
    <div className="container service-info-window mg-90">
      <h2>404 Error! Page not found!</h2>
      <Link to="/">Return to our homepage.</Link>
    </div>
  </Layout>
)

export default NotFoundPage
